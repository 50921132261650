import { Module, VuexModule, MutationAction, Action, Mutation } from 'vuex-module-decorators'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

export interface ActivitySession {
    activitySessionUuid: string;
    adminId: number;
    activityId: number;
    countryId: number;
    industryId: number;
    departmentId: number;
    name: string;
    status: number;
    language: string;
    maxTeamPlayers: number;
    startDate: string;
    expiryDate: string;
    createdAt: string;
    updatedAt: string;
    teamCount: number;
    userCount: number;
}

export interface ActivitySessionFilter {
    filter: {
        limit?: number,
        offset?: number
        name?: string
    }
}

interface ActivitySessionList {
    activitySessions: Array<string>
    activitySessionsCount: number,
    activitySessionRequestPending: boolean
}

@Module
export default class ActivitySessionModule extends VuexModule implements ActivitySessionList {
    activitySession = {};
    activitySessions = [];
    activitySessionsCount = 0;
    activitySessionsInProgressCount = 0;
    activitySessionsScheduledCount = 0;
    activitySessionsFinishedCount = 0;
    activitySessionRequestPending = false;
    activitySessionInsights = [];

    get getActivitySession () {
      return this.activitySession
    }

    /**
     * Get the list of activity sessions filtered
     * @returns array
     */
    get getActivitySessions (): Array<string> {
      return this.activitySessions
    }

    get getActivitySessionsInsights (): Array<string> {
      return this.activitySessionInsights
    }

    /**
     * Get the count for the list of activity sessions
     * @returns number
     */
    get getActivitySessionsCount (): number {
      return this.activitySessionsCount
    }

    /**
     * Get the count of in progress activity sessions
     * @returns number
     */
    get getActivitySessionsInProgressCount (): number {
      return this.activitySessionsInProgressCount
    }

    /**
     * Get the count of scheduled activity sessions
     * @returns number
     */
    get getActivitySessionsScheduledCount (): number {
      return this.activitySessionsScheduledCount
    }

    /**
     * Get the count of finished activity sessions
     * @returns number
     */
    get getActivitySessionsFinishedCount (): number {
      return this.activitySessionsFinishedCount
    }

    /**
     * Get the api request status for activity sessions
     * @returns boolean
     */
    get getActivitySessionRequestPending (): boolean {
      return this.activitySessionRequestPending
    }

    @Action({ rawError: true })
    async [Actions.API_GET_MUTATE_ACTIVITY_SESSION] (activitySessionUuid) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/activity-sessions/' + activitySessionUuid)
          .then(({ data }) => {
            this.context.commit(Mutations.SET_ACTIVITY_SESSION, data.payload)
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Mutation
    [Mutations.SET_ACTIVITY_SESSION] (activitySession) {
      this.activitySession = activitySession
    }

    @Mutation
    [Mutations.SET_ACTIVITY_SESSION_INSIGHTS] (insights) {
      this.activitySessionInsights = insights
    }

    @Action
    async [Actions.API_GET_ACTIVITY_SESSION_INSIGHTS] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/activity-sessions', payload.sessionUuid + '/team/' + payload.teamUuid + '/insights')
          .then(({ data }) => {
            console.log('setting activity session insights')
            this.context.commit(Mutations.SET_ACTIVITY_SESSION_INSIGHTS, data.payload.insights)
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    async [MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_COUNT] (payload) {
      const response = await ApiService.get('api/v1/activity-sessions', 'count', payload)
      const activitySessionsCount = response.data.payload.count
      return { activitySessionsCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_IN_PROGRESS_COUNT] () {
      const payload = { filter: { status: 3 } }
      const response = await ApiService.get('api/v1/activity-sessions', 'count', payload)
      const activitySessionsInProgressCount = response.data.payload.count
      return { activitySessionsInProgressCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_SCHEDULED_COUNT] () {
      const payload = { filter: { status: 2 } }
      const response = await ApiService.get('api/v1/activity-sessions', 'count', payload)
      const activitySessionsScheduledCount = response.data.payload.count
      return { activitySessionsScheduledCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_SESSIONS_FINISHED_COUNT] () {
      const payload = { filter: { status: 4 } }
      const response = await ApiService.get('api/v1/activity-sessions', 'count', payload)
      const activitySessionsFinishedCount = response.data.payload.count
      return { activitySessionsFinishedCount }
    }
}
