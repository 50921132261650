
export const saveItem = (key: string, item: any) => {
  sessionStorage.setItem(key, item)
}

export const getItem = (key: string) => {
  return sessionStorage.getItem(key)
}

export const deleteItem = (key: string) => {
  return sessionStorage.removeItem(key)
}

export const purge = () => {
  sessionStorage.clear()
}

export default { saveItem, getItem, deleteItem, purge }
