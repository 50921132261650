// @ts-ignore
import io, { Socket } from 'socket.io-client'
import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import { uuid } from 'vue-uuid'
import moment from 'moment'
import { RESOURCE_LOCK_LIST } from '@/store/modules/ResourceLockerModule'
import store from '@/store'
const log = require('../../core/server/utils/log')
// @ts-ignore
window.io = require('socket.io-client')
const dotenv = require('dotenv')
dotenv.config()

interface SocketPingData {
    pingRequestId: string,
    emmitTime: number,
    emmitDateTime: string,
    maxPingResponseTime: number;
}
const MOUSE_POSITIONS = 'team:%teamUuid%:mouse:positions'

@Module
export default class WebsocketModule extends VuexModule {
    adminErrors = {} as any
    socket = {} as Socket
    pingTimer: number | undefined = 0;
    lastPingRequestId: string | null = null;
    lastPingFailed: boolean = false;
    maxPingResponseTime = 5;
    pingEnabled: boolean = true;

    /**
     * Get socket
     * @returns Template
     */
    get getSocket () {
      return this.socket
    }

    get getLastPingRequestId () {
      return this.lastPingRequestId
    }

    get getPingTimer () {
      return this.pingTimer
    }

    get getAdminErrors () {
      return this.adminErrors
    }

    @Mutation
    [Mutations.SET_ADMIN_ERRORS] (error) {
      this.adminErrors = error
    }

    @MutationAction
    async [MutationsActions.SET_MUTATE_ACTIVE_SOCKET] (activeSocket) {
      const socket = await activeSocket
      return { socket }
    }

    @Mutation
    [Mutations.SET_LAST_PING_REQUEST_ID] (lastPingRequestId: string) {
      this.lastPingRequestId = lastPingRequestId
    }

    @Mutation
    [Mutations.SET_PING_TIMER] (pingTimer) {
      this.pingTimer = pingTimer
    }

    @Mutation
    [Mutations.CLEAR_TIMEOUT_PING_TIMER] () {
      window.clearTimeout(this.pingTimer)
    }

    @Action
    [Actions.CREATE_SOCKET_CONNECTION] (payload: any) {
      // const url = window.location.protocol + '//' + window.location.hostname + ':6003'
      const url = process.env.VUE_APP_CURIOSITY_WSS_PROTOCOL + '://' + process.env.VUE_APP_CURIOSITY_WSS_HOSTNAME + ':' + process.env.VUE_APP_CURIOSITY_WSS_PORT

      const socket = io(url, {
        query: { userSessionId: payload.userSessionId, teamUuid: payload.teamUuid },
        // withCredentials: false,
        // autoConnect: true,
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 5000,
        reconnectionDelayMax: 10000

      })

      socket.on('connect', () => {
        this.context.dispatch(Actions.ON_SOCKET_CONNECTED).then(

        )
      })
      socket.on('mousemove', data => {
        socket.emit('moving', data)
      })

      socket.on('connect_error', (eventData) => { this.context.dispatch(Actions.ON_SOCKET_CONNECTION_ERROR, eventData).then() })
      socket.on('reconnect', (eventData) => { this.context.dispatch(Actions.ON_SOCKET_RECONNECTED, eventData).then() })
      socket.on('disconnect', (eventData) => { this.context.dispatch(Actions.ON_SOCKET_DISCONNECTED, eventData).then() })
      socket.on('error', (eventData) => { this.context.dispatch(Actions.ON_SOCKET_ERROR, eventData).then() })
      socket.on('PING_RESPONSE', (eventData) => { this.context.dispatch(Actions.ON_PING_RESPONSE, eventData).then() })

      // socket.on('WIDGET_ADDED', (eventData) => {
      //   const payload = {
      //     widget: eventData.widget.content.widget,
      //     widgetPosition: eventData.widget.content.widgetPosition
      //   }
      //   this.context.commit(Mutations.SET_TEMPLATE_ADD_WIDGET, payload)
      // })
      //
      socket.on('WIDGET_EDITED', (eventData) => {
        const payload = {
          widget: eventData.content.widget,
          activeSectionIndex: eventData.content.locator.activeSectionIndex,
          activeWidgetIndex: eventData.content.locator.activeWidgetIndex
        }
        this.context.commit(Mutations.SET_TEMPLATE_EDIT_WIDGET, payload)
      })
      socket.on('WIDGET_TEAM_READINESS_CHECKED', (eventData) => {
        if (eventData.content.hotspot.hotspotUuid) {
          if (eventData.content.parentWidgetUuid) {
            this.context.commit(Mutations.UPDATE_WIDGET_EDIT_HOTSPOT, { widgetUuid: eventData.content.parentWidgetUuid, hotspot: eventData.content.hotspot })
          }
        } else {
          const payload = {
            widget: eventData.content.widget,
            activeSectionIndex: eventData.content.locator.activeSectionIndex,
            activeWidgetIndex: eventData.content.locator.activeWidgetIndex
          }
          this.context.commit(Mutations.SET_TEMPLATE_EDIT_WIDGET, payload)
        }
      })
      //
      // socket.on('SECTION_ADDED', (eventData) => {
      //   this.context.commit(Mutations.SET_TEMPLATE_ADD_SECTION, eventData.content.section)
      // })
      //
      // socket.on('SECTION_EDITED', (eventData) => {
      //   console.log('Section edited successfully')
      // })
      //
      socket.on('SECTION_LAYOUT_EDITED', (eventData) => {
        console.log('eventData  SECTION_LAYOUT_EDITED', eventData)
        const widgetPosition = eventData.content.widgetPosition
        const widgetUuid = widgetPosition.i
        const payload = { widgetUuid, widgetPosition } as any
        // payload.widgetUuid = widgetUuid
        // payload
        this.context.commit(Mutations.UPDATE_WIDGET_POSITION, payload)
        console.log('Section layout edited successfully')
      })
      //
      // socket.on('SECTION_REMOVED', (eventData) => {
      //   this.context.commit(Mutations.SET_TEMPLATE_REMOVE_SECTION, eventData.widget.content.locator.activeSectionIndex)
      //   console.log(eventData)
      // })
      //
      // socket.on('WIDGET_REMOVED', (eventData) => {
      //   this.context.commit(Mutations.SET_TEMPLATE_REMOVE_WIDGET_BY_UUID, eventData.content.widgetUuid)
      // })

      socket.on('ADMIN_ERROR', (eventData) => {
        this.context.commit(Mutations.SET_ADMIN_ERRORS, eventData.error)
      })

      socket.on('IN_GAME_METADATA_UPDATED', (eventData) => {
        this.context.commit(Mutations.SET_IN_GAME_METADATA, eventData.metadata)
      })

      socket.on('IN_GAME_SECTION_UPDATED', (eventData) => {
        this.context.commit(Mutations.UPDATE_SECTION_MODULE, eventData.section)
      })

      socket.on('IMAGE_360_HOTSPOT_EDITED', (eventData) => {
        this.context.commit(Mutations.UPDATE_WIDGET_EDIT_HOTSPOT, { widgetUuid: eventData.widgetUuid, hotspot: eventData.hotspot })
      })
      socket.on(MOUSE_POSITIONS.replace('%teamUuid%', payload.teamUuid), (eventData) => {
        store.commit(Mutations.UPDATE_MOUSE_POSITIONS, eventData)
      })

      socket.on(RESOURCE_LOCK_LIST.replace('%teamUuid%', payload.teamUuid), (lockList) => {
        store.commit(Mutations.UPDATE_LOCK_LIST, lockList)
      })

      return this.context.dispatch(MutationsActions.SET_MUTATE_ACTIVE_SOCKET, socket).then(() => {
        return socket
      })
    }

    @Action
    [Actions.ON_SOCKET_CONNECTED] () {
      console.log('SOCKET MANAGER', 'Socket CONNECTED.')

      this.context.dispatch(Actions.PING_REQUEST).then()
    }

    @Action
    [Actions.ON_SOCKET_CONNECTION_ERROR] () {
      console.log('SOCKET MANAGER', 'Socket connection error.')
      this.context.dispatch(Actions.PING_REQUEST).then()
    }

    @Action
    [Actions.ON_SOCKET_RECONNECTED] () {
      console.log('SOCKET MANAGER', 'Socket RECONNECTED')
      // this.emmitConnected()
    }

    @Action
    [Actions.ON_SOCKET_DISCONNECTED] () {
      console.log('SOCKET MANAGER', 'Socket DISCONNECTED.')
    }

    @Action
    [Actions.ON_SOCKET_ERROR] (eventData) {
      console.log('SOCKET MANAGER', 'Socket ERROR. ' + JSON.stringify(eventData.payload))
    }

    @Action
    [Actions.ON_PING_RESPONSE] (eventData) {
      const pingReqId = eventData.pingRequestId

      if (pingReqId !== this.context.getters.getLastPingRequestId) {
        return
      }

      this.context.commit(Mutations.SET_LAST_PING_REQUEST_ID, null)
      // todo hide the errors if any
    }

    @Action
    [Actions.PING_REQUEST] () {
      if (!this.pingEnabled) {
        return
      }
      if (this.context.getters.getPingTimer !== 0 && this.context.getters.getPingTimer !== null) {
        this.context.commit(Mutations.CLEAR_TIMEOUT_PING_TIMER)
      }

      this.context.commit(Mutations.SET_LAST_PING_REQUEST_ID, 'ping-test-' + uuid.v4())

      const emmitTime = new Date().getTime()
      const pingData: SocketPingData = {
        pingRequestId: this.context.getters.getLastPingRequestId,
        emmitTime: emmitTime,
        emmitDateTime: moment(emmitTime).format(),
        maxPingResponseTime: this.maxPingResponseTime
      }
      this.socket.emit('PING_REQUEST', pingData)

      this.context.commit(Mutations.SET_PING_TIMER, window.setTimeout(
        () => {
          this.context.commit(Mutations.SET_PING_TIMER, null)

          if (this.context.getters.getLastPingRequestId != null) {
            log.error('SOCKET MANAGER', 'The last ping request (' + this.lastPingRequestId + ') did not received a response in: ' + this.maxPingResponseTime + 's.')
            // todo show errors here
          }
          // continue pinging
          this.context.dispatch(Actions.PING_REQUEST).then()
        },
        this.maxPingResponseTime * 1000
      ))
    }
}
