/**
 * File writer.
 *
 * @type {object}
 */
module.exports = {
  fd: null,
  fs: require('fs'),
  init: function (callback) {
    const self = module.exports
    const file = process.env.VUE_APP_NODEJS_LOGGING_PATH
    const streamInit = function () {
      try {
        self.fd = self.fs.openSync(file, 'a')
      } catch (e) {
        self.fd = null
        console.log('ERROR: Could not init the file writer!', e)
      }

      callback()
    }

    self.fs.exists(file, function (exists) {
      if (!exists) {
        console.log('The log file "' + file + '" not exists. Will attempt to create ...')

        self.fs.writeFile(
          file, '\n', [],
          function (writingError) {
            if (writingError) {
              console.log('ERROR: The log file "' + file + '" could not be created.')
            } else {
              streamInit()
            }
          }
        )
      } else {
        streamInit()
      }
    })
  },
  write: function (message) {
    const self = module.exports

    if (!self.isInit()) {
      console.log('ERROR: File log writer was not inited properly. Could not log : "' + message + '" in file!')
      return
    }

    try {
      self.fs.writeSync(self.fd, message)
    } catch (e) {
      // nothing to do; cannot write in console because there is too much to write
    }
  },
  isInit: function () {
    const self = module.exports

    return (self.fd != null)
  }
}
