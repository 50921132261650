import { ActiveUser } from '@/store/modules/ActiveUserModule'

const ACTIVE_USER = 'active_user' as string

/**
 * @description get the active user from localStorage
 */
export const getActiveUser = (): ActiveUser | null => {
  const user = window.localStorage.getItem(ACTIVE_USER)
  if (user !== null) {
    return JSON.parse(user)
  }
  return user
}

/**
 * @description save csfr account uuid into localStorage
 * @param activeUser
 */
export const saveActiveUser = (activeUser: ActiveUser): void => {
  window.localStorage.setItem(ACTIVE_USER, JSON.stringify(activeUser))
}

/**
 * @description remove csrf account uuid form localStorage
 */
export const destroyActiveUser = (): void => {
  window.localStorage.removeItem(ACTIVE_USER)
}

export default { getActiveUser, saveActiveUser, destroyActiveUser }
