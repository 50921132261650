import { App } from 'vue'
import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'
import VueAxios from 'vue-axios'
import qs from 'qs'
import Swal from 'sweetalert2/dist/sweetalert2.min.js'
import router from '@/router/index'
import { isEmpty } from 'lodash'

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init (app: App<Element>) {
      ApiService.vueInstance = app
      ApiService.vueInstance.use(VueAxios, axios)
      ApiService.vueInstance.axios.defaults.headers = {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0'
      }
      ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL
      ApiService.vueInstance.axios.defaults.withCredentials = true
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader (): void {
      ApiService.vueInstance.axios.defaults.headers.common.Accept =
            'application/json'
    }

    public static setAuthorization (token) {
      if (isEmpty(token)) {
        ApiService.vueInstance.axios.defaults.headers.Authorization = null
        return
      }

      ApiService.vueInstance.axios.defaults.headers.Authorization = 'Bearer ' + token
    }

    public static getAuthorizationHeader (): string {
      return ApiService.vueInstance.axios.defaults.headers.Authorization
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query (
      resource: string,
      params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.get(resource, params)
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: object
     * @returns Promise<AxiosResponse>
     */
    public static get (
      resource: string,
      slug = '' as string,
      params = {} as object
    ): Promise<AxiosResponse | any> {
      return ApiService.vueInstance.axios.get(`${resource}/${slug}`, {
        params: params,
        paramsSerializer: params => {
          return qs.stringify(params)
        }
      }).catch(({ response }) => {
        if (response.status === 401) {
          router.push('/sign-in')
        } else if (response.status === 400 && router.currentRoute.value.name === 'engageTryItNow') {
          // ignore lead not found
          return response
        } else { // show general error messages
          Swal.fire({
            text: response.data.error.message,
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: 'Try again',
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger'
            }
          })
        }
      })
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @param config
     * @returns Promise<AxiosResponse>
     */
    public static post (
      resource: string,
      params: AxiosRequestConfig,
      config: AxiosRequestConfig = {}
    ): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.post(`${resource}`, params, config)
    }

    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @param config
     * @returns Promise<AxiosResponse>
     */
    public static update (
      resource: string,
      slug: string,
      params: AxiosRequestConfig,
      config: AxiosRequestConfig = {}
    ): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params, config)
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put (
      resource: string,
      params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.put(`${resource}`, params)
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static delete (
      resource: string,
      params: AxiosRequestConfig = {} as object
    ): Promise<AxiosResponse> {
      return ApiService.vueInstance.axios.delete(`${resource}`, params)
    }
}

export default ApiService
