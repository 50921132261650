import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations } from '@/store/enums/StoreEnums'

@Module
export default class ActivityCountdown extends VuexModule {
    startActivityCountdown = 0

    get getStartActivityCountdown () {
      return this.startActivityCountdown
    }

    @Action
    [Actions.SET_START_ACTIVITY_COUNTDOWN] (startActivityCountdown) {
      this.context.commit(Mutations.SET_ACTIVITY_COUNTDOWN, startActivityCountdown)
    }

    @Mutation
    [Mutations.SET_ACTIVITY_COUNTDOWN] (activityCountdown) {
      this.startActivityCountdown = activityCountdown
    }
}
