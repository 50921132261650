import ApiService from '@/core/services/ApiService'
import CsrfService from '@/core/services/CsrfService'
import { isEmpty } from 'lodash'
import localforage from 'localforage'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import { Module, Action, Mutation, VuexModule } from 'vuex-module-decorators'
import { ActiveUser } from '@/store/modules/ActiveUserModule'
import LocalStorage from '@/core/services/LocalStorage'
import router from '@/router/index'

@Module
export default class AuthModule extends VuexModule {
    activeUser = null
    isAuthenticated = !!CsrfService.getCsrfActiveUserUuid();

    /**
     * Get current account object
     * @returns Account
     */
    get currentActiveUser (): ActiveUser | null {
      return this.activeUser
    }

    /**
     * Verify account authentication
     * @returns boolean
     */
    get isAccountAuthenticated (): boolean {
      return this.isAuthenticated
    }

    @Mutation
    [Mutations.SET_AUTH_ACTIVE_USER] (activeUser) {
      console.log('SET_AUTH_ACTIVE_USER', activeUser)
      this.isAuthenticated = true
      // if (typeof (this.activeUser.activeUserUuid) === 'undefined') {
      this.activeUser = activeUser
      // }
      LocalStorage.saveActiveUser(activeUser)
      CsrfService.saveCsrfActiveUserUuid(activeUser.activeUserUuid)
    }

    @Mutation
    [Mutations.SET_ACTIVE_USER] (activeUser) {
      this.activeUser = activeUser
    }

    @Mutation
    [Mutations.PURGE_AUTH] () {
      this.isAuthenticated = false
      // this.activeUser = {} as ActiveUser
      CsrfService.destroyCsrfActiveUserUuid()
      LocalStorage.destroyActiveUser()
    }

    @Action
    [Actions.API_GET_CSRF_TOKEN] () {
      return ApiService.get('api/csrf-cookie')
    }

    @Action({ rawError: true })
    async [Actions.API_ACTIVE_USER_PUBLIC_LOGIN] (details) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/public-session/' + details.publicSessionUuid + '/user', details)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_ACTIVE_USER_LOGIN] (credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/oauth/user/login', credentials)
          .then(({ data }) => {
            this.context.dispatch(Actions.SET_TOKEN, data.payload.token.access_token).then(() => {
              this.context.dispatch(Actions.FETCH_USER)
            })
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }
    // async [Actions.API_ACTIVE_USER_LOGIN] (credentials) {
    //   return new Promise((resolve, reject) => {
    //     ApiService.post('api/v1/user/login', credentials)
    //       .then(({ data }) => {
    //         this.context.commit(Mutations.SET_AUTH_ACTIVE_USER, data.payload)
    //         resolve(data)
    //       })
    //       .catch(({ response }) => {
    //         reject(response)
    //       })
    //   })
    // }

    @Action
    [Actions.CHECK_TOKEN_EXISTS] () {
      return localforage.getItem('authToken').then((token) => {
        if (isEmpty(token)) {
          return Promise.reject(new Error('NO_STORAGE_TOKEN'))
        }
        return localforage.getItem('forceLogout').then((isForceLogout: any) => {
          if (isForceLogout === true) {
            return this.context.dispatch(Actions.CLEAR_AUTHENTICATION).then(() => {
              return Promise.reject(new Error('FORCE_LOGOUT'))
            })
          }
          return Promise.resolve(token)
        })
      })
    }

    @Action
    [Actions.SET_TOKEN] (token) {
      if (isEmpty(token)) {
        return this.context.dispatch(Actions.CHECK_TOKEN_EXISTS).then((token) => {
          ApiService.setAuthorization(token)
        })
      }

      this.context.commit(Mutations.STORE_TOKEN, token)
      ApiService.setAuthorization(token)
    }

    @Action
    [Actions.FETCH_USER] () {
      return ApiService.get('api/v1/oauth/user').then((response) => {
        this.context.commit(Mutations.SET_AUTHENTICATED, true)
        this.context.commit(Mutations.SET_ACCOUNT_DATA, response.data.payload)
      })
    }

    @Action
    [Actions.CLEAR_AUTHENTICATION] () {
      this.context.commit(Mutations.SET_AUTHENTICATED, false)
      this.context.commit(Mutations.SET_ACCOUNT_DATA, null)
      this.context.commit(Mutations.STORE_TOKEN, null)
      this.context.commit(Mutations.SET_FORCE_LOGOUT, false)
      ApiService.setAuthorization(null)
    }

    @Mutation
    [Mutations.SET_FORCE_LOGOUT] (forceLogout) {
      localforage.setItem('forceLogout', forceLogout).then(() => {
        localforage.getItem('intended').then((name: any) => {
          if (isEmpty(name)) {
            // router.replace({ name: 'console' })
            return
          }
          router.replace({ name: name })
        })
      })
    }

    @Mutation
    [Mutations.STORE_TOKEN] (token) {
      if (isEmpty(token)) {
        // admin token
        localforage.removeItem('authToken', token)
        return
      }

      // Check empty remove token
      localforage.setItem('authToken', token)
    }

    @Mutation
    [Mutations.SET_AUTHENTICATED] (isAuthenticated) {
      this.isAuthenticated = isAuthenticated
    }

    @Mutation
    [Mutations.SET_ACCOUNT_DATA] (accountData) {
      this.activeUser = accountData
    }

    @Action
    [Actions.ACTIVE_USER_LOGOUT] () {
      return ApiService.get('api/v1/oauth/account/logout').then(() => {
        this.context.dispatch(Actions.CLEAR_AUTHENTICATION).then()
      })
    }

    @Action
    [Actions.API_VERIFY_AUTH] () {
      // this.activeUser = { } as ActiveUser
      console.log('API_VERIFY_AUTH ')

      if (!CsrfService.getCsrfActiveUserUuid()) {
        console.log('API_VERIFY_AUTH -- failed getCsrfActiveUserUuid nothing -- purging')
        this.context.commit(Mutations.PURGE_AUTH)
        return
      }

      return ApiService.get('api/csrf-check')
        .then(({ data }) => {
          console.log('DOING api/csrf-check -- success')
          if (data.payload.company !== null && typeof data.payload.company !== 'undefined') {
            // if it is an actual account logged in
            console.log('DOING api/csrf-check -- success -- purging because it has company')

            this.context.commit(Mutations.PURGE_AUTH)
            return
          }

          this.context.commit(Mutations.SET_AUTH_ACTIVE_USER, data.payload)
        })
        .catch(({ response }) => {
          console.log('DOING api/csrf-check -- failed')

          if (response.status === 401) {
            this.context.commit(Mutations.PURGE_AUTH)
          }
        })
    }
}
