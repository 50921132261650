const ACTIVE_USER_UUID = 'csrf_active_user_uuid' as string

/**
 * @description get csrf uuid of the active user from localStorage
 */
export const getCsrfActiveUserUuid = (): string | null => {
  return window.localStorage.getItem(ACTIVE_USER_UUID)
}

/**
 * @description save csfr account uuid into localStorage
 * @param uuid: string
 */
export const saveCsrfActiveUserUuid = (uuid: string): void => {
  window.localStorage.setItem(ACTIVE_USER_UUID, uuid)
}

/**
 * @description remove csrf account uuid form localStorage
 */
export const destroyCsrfActiveUserUuid = (): void => {
  window.localStorage.removeItem(ACTIVE_USER_UUID)
}

export default { getCsrfActiveUserUuid, saveCsrfActiveUserUuid, destroyCsrfActiveUserUuid }
