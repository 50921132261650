import { Action, Module, VuexModule } from 'vuex-module-decorators'
import { Actions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

@Module
export default class PreIntroModule extends VuexModule {
    @Action({ rawError: true })
  async [Actions.API_CHOOSE_CHARACTER] (payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('api/v1/user/chooseCharacter', payload)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  }
}
