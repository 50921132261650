import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'
import { computed } from 'vue'
import store from '@/store'

export interface ActionModeInterface {
    requestedBy: Object
    acceptedBy: Array<object>
    declinedBy: Object
}

export interface MultiActionModeInterface {
    resourceUuid: string
    requestedBy: Object
    acceptedBy: Array<object>
    declinedBy: Object
}

export interface QuestionnaireInterface {
    confidence: Array<object>
}

export interface MetadataActionsInterface {
    startActivity: ActionModeInterface
    endActivity: ActionModeInterface
    questionnaire: QuestionnaireInterface
    checkReadiness: Array<MultiActionModeInterface>
}

export interface GameStartedInterface {
    reason: string | null,
    timestamp: number | null
}

export interface InGameMetadataInterface {
    participants: Array<any>
    connected: Array<any>
    actions: MetadataActionsInterface
    started: GameStartedInterface
    ended: GameStartedInterface
}

export interface ActivityTeamInterface {
    activityTeamUuid: string,
    teamName: string,
    inGameMetadata: InGameMetadataInterface
    startedAt: string | null,
    endedAt: string | null
}

export const MOUSE_MOVE = 'team:%teamUuid%:mouse:move'

@Module
export default class ActivityTeamModule extends VuexModule {
    activityTeam: ActivityTeamInterface = <ActivityTeamInterface>{}
    mousePositions = []

    get getInGameMetadata () {
      return this.activityTeam.inGameMetadata
    }

    get getActivityTeam (): ActivityTeamInterface {
      return this.activityTeam
    }

    get getMousePositions () {
      return this.mousePositions
    }

    get getParticipantMetadata (): (activeUserUuid: string) => any {
      const _this = this
      return function (activeUserUuid: string) {
        if (!computed(() => _this.context.getters.getActivityTeam).value.inGameMetadata) {
          return { userColorLabel: 'uc1' }
        }
        let connectedUser = computed(() => _this.context.getters.getActivityTeam).value.inGameMetadata.connected.find((item: any) => item.activeUserUuid === activeUserUuid) as any
        if (!connectedUser) {
          connectedUser = _this.context.getters.getActivityTeam.inGameMetadata.connected.find((item: any) => item.userUuid === activeUserUuid) as any
        }
        if (typeof connectedUser === 'undefined') {
          connectedUser = _this.context.getters.getActivityTeam.inGameMetadata.participants.find((item: any) => item.userUuid === activeUserUuid) as any
        }

        if (connectedUser) {
          return (_this.context.getters.getActivityTeam.inGameMetadata.participants.find((item: any) => item.userUuid === connectedUser.userUuid))
        }
      }
    }

    get totalParticipantsCount () {
      return this.activityTeam.inGameMetadata.participants.length
    }

    get totalConnectedParticipantsCount () {
      return this.activityTeam.inGameMetadata.connected.length
    }

    get totalConnectedNotObserversCount () {
      return this.activityTeam.inGameMetadata?.connected.filter(participant => participant.userRole !== 2).length
    }

    get getConnectedParticipants () {
      return this.activityTeam.inGameMetadata?.connected
    }

    get areAllParticipantsConnected (): boolean {
      return this.activityTeam.inGameMetadata?.connected.filter(participant => participant.userRole !== 2).length === this.activityTeam.inGameMetadata?.participants.filter(participant => participant.userRole !== 2).length
    }

    get startRequestedBy () {
      return this.activityTeam.inGameMetadata?.actions.startActivity.requestedBy
    }

    get endRequestedBy () {
      return this.activityTeam.inGameMetadata?.actions.endActivity.requestedBy
    }

    get startActivityAcceptedBy () {
      return this.activityTeam.inGameMetadata?.actions.startActivity.acceptedBy
    }

    get checkReadiness () {
      return this.activityTeam.inGameMetadata?.actions.checkReadiness
    }

    get checkReadinessRequestedBy () {
      const _this = this
      return function (widgetUuid: string) {
        const checkReadiness = _this.activityTeam.inGameMetadata?.actions.checkReadiness.filter(entry => entry.resourceUuid === widgetUuid)
        return checkReadiness.length === 1 ? checkReadiness[0].requestedBy : {}
      }
    }

    get checkReadinessAcceptedBy () {
      const _this = this
      return function (widgetUuid: string) {
        const checkReadiness = _this.activityTeam.inGameMetadata?.actions.checkReadiness.filter(entry => entry.resourceUuid === widgetUuid)
        return checkReadiness.length === 1 ? checkReadiness[0].acceptedBy : []
      }
    }

    get checkReadinessDeclinedBy () {
      const _this = this
      return function (widgetUuid: string) {
        const checkReadiness = _this.activityTeam.inGameMetadata?.actions.checkReadiness.filter(entry => entry.resourceUuid === widgetUuid)
        return checkReadiness.length === 1 ? checkReadiness[0].declinedBy : {}
      }
    }

    get checkReadinessVoteInProgress () {
      const _this = this
      return function (widgetUuid: string) {
        const checkReadiness = _this.activityTeam.inGameMetadata?.actions.checkReadiness.filter(entry => entry.resourceUuid === widgetUuid)
        const requestedBy = checkReadiness.length === 1 ? checkReadiness[0].requestedBy : {} as any
        return requestedBy.activeUserUuid !== undefined
      }
    }

    get endActivityAcceptedBy () {
      return this.activityTeam.inGameMetadata?.actions.endActivity.acceptedBy
    }

    get startDeclinedBy () {
      return this.activityTeam.inGameMetadata?.actions.startActivity.declinedBy
    }

    get endDeclinedBy () {
      return this.activityTeam.inGameMetadata?.actions.endActivity.declinedBy
    }

    get getTeamStarted () {
      return this.activityTeam.inGameMetadata?.started
    }

    get getTeamEnded () {
      return this.activityTeam.inGameMetadata?.ended
    }

    get hasParticipantAnsweredConfidence () {
      const _this = this
      return function (activeUserUuid: string) {
        return (_this.activityTeam.inGameMetadata?.actions.questionnaire.confidence.filter(function (e: any) {
          return e.activeUserUuid === activeUserUuid
        }).length > 0)
      }
    }

    @Action({ rawError: true })
    async [Actions.API_GET_MUTATE_ACTIVITY_TEAM] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/activity-session-team/' + payload.teamUuid)
          .then(({ data }) => {
            this.context.commit(Mutations.SET_ACTIVITY_TEAM, data.payload)
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.MOVE_MOUSE] (data) {
      const socket = store.getters.getSocket
      return new Promise(resolve => {
        socket.emit(MOUSE_MOVE.replace('%teamUuid%', socket.io.opts.query.teamUuid), data, resolve)
      })
    }

    @Mutation
    [Mutations.UPDATE_MOUSE_POSITIONS] (mousePositions: []) {
      this.mousePositions = mousePositions
    }

    @Mutation
    [Mutations.SET_IN_GAME_METADATA] (inGameMetadata) {
      this.activityTeam.inGameMetadata = inGameMetadata
    }

    @Mutation
    [Mutations.SET_ACTIVITY_TEAM] (activityTeam) {
      this.activityTeam = activityTeam
    }
}
