import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

export type ChatMessage = {
    messageId: number,
    content: string,
    generatedByAi: boolean,
    createdAt: string,
    updatedAt: string,
}

export type ChatMessagesList = Array<ChatMessage>

@Module
export default class AiTrainerModule extends VuexModule {
    chatMessagesList: ChatMessagesList = []

    get getChatMessagesList (): ChatMessagesList {
      return this.chatMessagesList
    }

    @Action({ rawError: true })
    async [Actions.API_GET_CHAT_MESSAGES_LIST] () {
      return new Promise((resolve, reject) => {
        ApiService.get('api/v1/openai/debriefing/getLatestMessages')
          .then(({ data }) => {
            this.context.commit(Mutations.SET_CHAT_MESSAGES_LIST, data.payload)
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_CREATE_CHAT_MESSAGE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/openai/debriefing/chat', payload)
          .then(({ data }) => {
            this.context.commit(Mutations.ADD_CHAT_MESSAGE, data.payload)
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Mutation
    [Mutations.SET_CHAT_MESSAGES_LIST] (chatMessagesList: ChatMessagesList) {
      this.chatMessagesList = chatMessagesList
    }

    @Mutation
    [Mutations.ADD_CHAT_MESSAGE] (chatMessage: ChatMessage) {
      this.chatMessagesList.push(chatMessage)
    }
}
