import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import { Actions } from '@/store/enums/StoreEnums'
import localforage from 'localforage'
import { uuid } from 'vue-uuid'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'curiosity-session',
    meta: {
      guest: false,
      needsAuth: true
    },
    component: () => import('@/views/session/BuilderLayout.vue')
  },
  {
    path: '/customize-character',
    name: 'customize-character',
    meta: {
      guest: false,
      needsAuth: true
    },
    component: () => import('@/views/session/PreIntro/PreIntro.vue')
  },
  {
    path: '/engage/:id',
    name: 'engage',
    meta: {
      guest: true,
      needsAuth: false
    },
    component: () => import('@/views/authentication/Login.vue')
  },
  {
    path: '/engage-public/:id',
    name: 'engagePublic',
    meta: {
      guest: true,
      needsAuth: false
    },
    component: () => import('@/views/authentication/LoginPublic.vue')
  },
  {
    path: '/engage-try-it-now',
    name: 'engageTryItNowLanding',
    meta: {
      guest: true,
      needsAuth: false
    },
    redirect: to => {
      return { path: '/engage-try-it-now/' + uuid.v4() }
    }
  },
  {
    path: '/engage-try-it-now/:id',
    name: 'engageTryItNow',
    meta: {
      guest: true,
      needsAuth: false
    },
    component: () => import('@/views/authentication/LoginTryItNow.vue')
  },
  {
    path: '/401',
    name: '401',
    meta: {
      guest: true,
      needsAuth: false
    },
    component: () => import('@/views/error/Error401.vue')
  },
  {
    path: '/404',
    name: '404',
    meta: {
      guest: true,
      needsAuth: false
    },
    component: () => import('@/views/error/Error404.vue')
  },
  {
    path: '/500',
    name: '500',
    meta: {
      guest: true,
      needsAuth: false
    },
    component: () => import('@/views/error/Error500.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  store.dispatch(Actions.CHECK_TOKEN_EXISTS).then(() => {
    if (to.meta.guest && !['engage', 'engagePublic', 'engageTryItNow', 'engageTryItNowLanding'].includes(<string>to.name)) {
      next({ name: '401' })
      return
    }
    next()
  }).catch(() => {
    if (to.meta.needsAuth) {
      localforage.setItem('intended', to.name)
      next({ name: '401' })
      return
    }
    next()
  })
  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)
})

export default router
