import { createStore } from 'vuex'
import { config } from 'vuex-module-decorators'

import AuthModule from '@/store/modules/AuthModule'
import BodyModule from '@/store/modules/BodyModule'
import ConfigModule from '@/store/modules/ConfigModule'
import TemplateModule from '@/store/modules/TemplateModule'
import ActivitySessionModule from '@/store/modules/ActivitySessionModule'
import WebsocketModule from '@/store/modules/WebsocketModule'
import ActivityTeamModule from '@/store/modules/ActivityTeamModule'
import ActivityCountdown from '@/store/modules/ActivityCountdown'
import ResourceLockerModule from '@/store/modules/ResourceLockerModule'
import LeadModule from '@/store/modules/Lead'
import AiTrainerModule from '@/store/modules/AiTrainerModule'
import PreIntroModule from '@/store/modules/PreIntroModule'
// import ActiveUserModule from '@/store/modules/ActiveUserModule'

config.rawError = true

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    TemplateModule,
    ActivitySessionModule,
    WebsocketModule,
    ActivityTeamModule,
    ActivityCountdown,
    ResourceLockerModule,
    LeadModule,
    AiTrainerModule,
    PreIntroModule

    // ActiveUserModule
  }
})

export default store
