/* eslint-disable */
enum Actions {
    
    // activity session 
    API_GET_MUTATE_ACTIVITY_SESSION = 'apiGetMutateActivitySession',
    
    ADD_BODY_CLASSNAME = 'addBodyClassName',
    REMOVE_BODY_CLASSNAME = 'removeBodyClassName',
    ADD_BODY_ATTRIBUTE = 'addBodyAttribute',
    REMOVE_BODY_ATTRIBUTE = 'removeBodyAttribute',
    ADD_CLASSNAME = 'addClassName',
    
    API_GET_CSRF_TOKEN = 'apiGetCsrfToken',
    API_VERIFY_AUTH = 'apiVerifyAuth',
    API_ACTIVE_USER_LOGIN = 'apiActiveUserLogin',
    API_ACTIVE_USER_PUBLIC_LOGIN = 'apiActiveUserPublicLogin',
    ACTIVE_USER_LOGOUT = 'activeUserLogout',

    // Websocket
    CREATE_SOCKET_CONNECTION = 'createSocketConnection',
    ON_SOCKET_CONNECTED = 'onSocketConnected',
    ON_SOCKET_CONNECTION_ERROR = 'onSocketConnectionError',
    ON_SOCKET_RECONNECTED = 'onSocketReconnected',
    ON_SOCKET_DISCONNECTED = 'onSocketDisconnected',
    ON_SOCKET_ERROR = 'onSocketError',
    ON_SERVICE_MESSAGE = 'onServiceMessage',
    ON_PING_RESPONSE = 'onPingResponse',
    PING_REQUEST = 'pingRequest',

    // Template 
    API_CREATE_ACTIVITY_TEMPLATE = 'apiCreateActivityTemplate',
    API_UPDATE_ACTIVITY_TEMPLATE = 'apiUpdateActivityTemplate',
    API_UPLOAD_ACTIVITY_TEMPLATE_IMAGE = 'apiUploadActivityTemplateImage',
    API_DELETE_ACTIVITY_TEMPLATE = 'apiDeleteActivityTemplate',
    API_UNLOCK_SECTION = 'apiUnlockSection',

    // insights 
    API_GET_ACTIVITY_SESSION_INSIGHTS = 'apiGetMutateActivitySessionInsights',
    
    // team
    API_GET_MUTATE_ACTIVITY_TEAM = 'apiGetMutateActivityTeam',

    SET_START_ACTIVITY_COUNTDOWN = 'setStartActivityCountdown',
    
    // locker 
    LOCK_RESOURCE = 'lockResource',
    UNLOCK_RESOURCE = 'unlockResource',

    // OAuth 
    SET_TOKEN = 'setToken',
    CHECK_TOKEN_EXISTS = 'checkTokenExists',
    FETCH_USER = 'fetchUser',
    CLEAR_AUTHENTICATION = 'clearAuthentication',
    
    // Mouse 
    MOVE_MOUSE = 'moveMouse',
    
    // Leads
    API_GET_LEAD_BY_UUID = 'apiGetLeadByUuid',
    API_CREATE_LEAD = 'apiCreateLead',
    API_UPDATE_LEAD = 'apiUpdateLead',
    
    // AiTrainer
    API_GET_CHAT_MESSAGES_LIST = 'apiGetChatMessagesList',
    API_CREATE_CHAT_MESSAGE = 'apiCreateChatMessage',
    
    // Pre Intro 
    API_CHOOSE_CHARACTER = 'apiChooseCharacter' 
}

enum Mutations {
    SET_CLASSNAME_BY_POSITION = 'appendBreadcrumb',
    SET_LAYOUT_CONFIG = 'setLayoutConfig',
    RESET_LAYOUT_CONFIG = 'resetLayoutConfig',
    OVERRIDE_LAYOUT_CONFIG = 'overrideLayoutConfig',
    OVERRIDE_PAGE_LAYOUT_CONFIG = 'overridePageLayoutConfig',

    // activity session
    SET_ACTIVITY_SESSION = 'setActivitySession',

    // active user
    SET_AUTH_ACTIVE_USER = 'setAuthActiveUser',
    SET_ACTIVE_USER = 'setActiveUser',
    PURGE_AUTH = 'purgeAuth',
    
    // Websocket
    SET_LAST_PING_REQUEST_ID = 'setLastPingRequestId',
    SET_PING_TIMER = 'setPingTimer',
    CLEAR_TIMEOUT_PING_TIMER = 'clearTimeoutPingTimer',
    SET_ADMIN_ERRORS = 'setAdminErrors',

    // Template
    API_SET_TEMPLATE = 'apiSetTemplate',
    SET_ACTIVITY_TEMPLATE_SOLVING_MODE = 'setActivityTemplateSolvingMode',
    SET_ACTIVITY_TEMPLATE = 'setActivityTemplate',
    SET_TEMPLATE_ACTIVE_SECTION_INDEX = 'setTemplateActiveSectionIndex',
    SET_TEMPLATE_ACTIVE_WIDGET_INDEX = 'setTemplateActiveWidgetIndex',
    SET_TEMPLATE_ADD_SECTION = 'setTemplateAddSection',
    SET_TEMPLATE_REMOVE_SECTION = 'setTemplateRemoveSection',
    SET_TEMPLATE_ADD_WIDGET = 'setTemplateAddWidget',
    SET_TEMPLATE_EDIT_WIDGET = 'setTemplateEditWidget',
    SET_TEMPLATE_REMOVE_WIDGET_BY_UUID = 'setTemplateRemoveWidgetByUuid',
    API_SET_ACTIVITY_TEMPLATES_REQUEST_PENDING = 'apiSetActivityTemplatesRequestPending',
    REMOVE_ACTIVITY_TEMPLATE = 'removeActivityTemplate',
    ADD_HOTSPOT_EXPANDED_WIDGETS = 'addHotspotExpandedWidget',
    REMOVE_HOTSPOT_EXPANDED_WIDGETS = 'removeHotspotExpandedWidget',
    SET_ACTIVITY_TEMPLATE_MOBILE_VIEW_MODE = 'setActivityTemplateMobileViewMode',

    SET_ACTIVITY_SESSION_INSIGHTS = 'setActivitySessionInsights',

    SET_IN_GAME_METADATA = 'setInGameMetadata',
    UPDATE_SECTION_MODULE = 'updateSectionModule',
    UPDATE_WIDGET_POSITION = 'updateWidgetPosition',
    SET_ACTIVITY_TEAM = 'setActivityTeam',

    SET_ACTIVITY_COUNTDOWN = 'setActivityCountdown',
    
    UPDATE_LOCK_LIST = 'updateLockList',
    
    SET_TEMPLATE_WIDGET_DISCOVERED = 'setTemplateWidgetDiscovered',
    
    // OAuth
    STORE_TOKEN = 'storeToken',
    SET_AUTHENTICATED = 'setAuthenticated',
    SET_ACCOUNT_DATA = 'setAccountData',
    SET_FORCE_LOGOUT = 'setForceLogout',
    
    // Mouse
    UPDATE_MOUSE_POSITIONS = 'updateMousePositions',

    SET_SELECTED_SCENE_FOR_WIDGET = 'setSelectedSceneForWidget',
    SET_SELECTED_HOTSPOT_IN_SCENE = 'setSelectedHotspotInScene',
    UPDATE_WIDGET_EDIT_HOTSPOT = 'updateWidgetEditHotspot',
    
    // AiTrainer
    SET_CHAT_MESSAGES_LIST = 'setChatMessagesList',
    ADD_CHAT_MESSAGE = 'addChatMessage',
}

enum MutationsActions {
    // Template
    API_GET_MUTATE_TEMPLATE = 'apiGetMutateTemplate',
    API_GET_MUTATE_TEMPLATE_BY_TEAM = 'apiGetMutateTemplateByTeam',
    
    API_GET_MUTATE_ACTIVITY_TEMPLATES = 'apiGetMutateActivityTemplates',
    API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT = 'apiGetMutateActivityTemplatesCount',
    API_GET_MUTATE_ALL_TEMPLATES_COUNT = 'apiGetMutateAllTemplatesCount',
    API_GET_MUTATE_READY_TEMPLATES_COUNT = 'apiGetMutateReadyTemplatesCount',
    API_GET_MUTATE_DRAFT_TEMPLATES_COUNT = 'apiGetMutateDraftTemplatesCount',

    // Activity Session
    API_GET_MUTATE_ACTIVITY_SESSION = 'apiGetMutateActivitySession',
    API_GET_MUTATE_ACTIVITY_SESSIONS = 'apiGetMutateActivitySessions',
    API_GET_MUTATE_ACTIVITY_SESSIONS_COUNT = 'apiGetMutateActivitySessionsCount',
    API_GET_MUTATE_ACTIVITY_SESSIONS_IN_PROGRESS_COUNT = 'apiGetMutateActivitySessionsInProgressCount',
    API_GET_MUTATE_ACTIVITY_SESSIONS_SCHEDULED_COUNT = 'apiGetMutateActivitySessionsScheduledCount',
    API_GET_MUTATE_ACTIVITY_SESSIONS_FINISHED_COUNT = 'apiGetMutateActivitySessionsFinishedCount',


    API_GET_MUTATE_LATEST_ACTIVITY_TEMPLATES = 'apiGetMutateLatestActivityTemplates',

    // Learning Paths 
    API_GET_MUTATE_LEARNING_PATH = 'apiGetMutateLearningPath',
    API_GET_MUTATE_LEARNING_PATHS = 'apiGetMutateLearningPaths',
    API_GET_MUTATE_LEARNING_PATHS_COUNT = 'apiGetMutateLearningPathsCount',

    // Employees
    API_GET_MUTATE_EMPLOYEES = 'apiGetMutateEmployees',
    API_GET_MUTATE_EMPLOYEES_COUNT = 'apiGetMutateEmployeesCount',

    // Websocket
    SET_MUTATE_ACTIVE_SOCKET = 'setMutateActiveSocket',
    
    // Teams
    API_GET_MUTATE_ACTIVITY_TEAM = 'apiGetMutateActivityTeam',
    
    
    SET_MUTATE_START_ACTIVITY_COUNTDOWN = 'setMutateStartActivityCountdown'



}

export { Actions, Mutations, MutationsActions }
