import { Action, Module, VuexModule } from 'vuex-module-decorators'
import { Actions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'

export interface LeadInterface {
    leadUuid: string;
    requestIp: string;
    name?: string;
    email: string;
    phone?: string;
    category?: string;
    activeUsers?: object
}

@Module
export default class LeadModule extends VuexModule {
    @Action({ rawError: true })
  async [Actions.API_GET_LEAD_BY_UUID] (leadUuid) {
    return new Promise((resolve, reject) => {
      ApiService.get('api/v1/lead', leadUuid)
        .then(({ data }) => {
          resolve(data)
        })
        .catch(({ response }) => {
          reject(response)
        })
    })
  }

    @Action({ rawError: true })
    async [Actions.API_CREATE_LEAD] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/lead', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UPDATE_LEAD] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.update('api/v1/lead', payload.leadUuid, payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }
}
