import { createApp } from 'vue'
import App from './App.vue'

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from './router/index'
import store from './store'
import ElementPlus from 'element-plus'
// import i18n from '@/core/plugins/i18n'
import VueSnip from 'vue-snip'

// imports for app initialization
import ApiService from '@/core/services/ApiService'
// import { initApexCharts } from '@/core/plugins/apexcharts'
import { initInlineSvg } from '@/core/plugins/inline-svg'
import { initVeeValidate } from '@/core/plugins/vee-validate'
import mitt from 'mitt' // Import mitt
// import '@/core/plugins/prismjs'
import 'bootstrap'
import localforage from 'localforage'
import { createI18n } from 'vue-i18n'
// import { determineCountryFromCoordinates } from './core/helpers/geolocation'

/**
 * Local forage main configuration.
 */
localforage.config({
  name: 'teamlearning',
  storeName: 'store'
})
const emitter = mitt() // Initialize mitt

const app = createApp(App)

const i18n = createI18n({
  legacy: false, // Enable if you're using Vue 2 compatibility mode
  locale: 'en', // Default locale
  messages: {
    en: require('../locales/en.json'), // Load your translation files
    ro: require('../locales/ro.json')
    // Add more languages as needed
  }
})
const globalComposerInstance = i18n.global

// this is needed if you want to change the locale based on location

// Get user's location using geolocation API
// navigator.geolocation.getCurrentPosition(
//   async (position) => {
//     const { latitude, longitude } = position.coords
//     console.log('lat', latitude)
//     console.log('lon', longitude)
//     const country = await determineCountryFromCoordinates(latitude, longitude)
//     i18n.global.locale.value = mapCountryToLocale(country)
//   },
//   (error) => {
//     console.error('Geolocation error:', error)
//   }
// )

// Function to map the user's country to a locale
// const mapCountryToLocale: any = (country: string) => {
//   const countryLocaleMap: Record<string, string> = {
//     us: 'en',
//     gb: 'en',
//     ro: 'ro'
//   }
//
//   return countryLocaleMap[country] || 'en' // Default to English if no mapping found
// }

app.config.globalProperties.$t = globalComposerInstance
app.provide('emitter', emitter)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(VueSnip)
ApiService.init(app)
// initApexCharts(app)
initInlineSvg(app)
initVeeValidate()
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js').then((registration) => {
      console.log('Service worker registered:', registration)
    }).catch((error) => {
      console.error('Service worker registration failed:', error)
    })
  })
}
app.use(i18n)

router.isReady().then(() => {
  app.mount('#app')
})
