import { Action, Module, Mutation, MutationAction, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations, MutationsActions } from '@/store/enums/StoreEnums'
import ApiService from '@/core/services/ApiService'
import {
  ActivityTemplate,
  Section,
  Template,
  Widget,
  WidgetMobilePosition,
  WidgetPosition
} from '@/core/helpers/template/TemplateInterface'
import { computed } from 'vue'
import store from '@/store'
import SessionStorage from '@/core/services/SessionStorage'

export interface NewTemplatePayload {
    title: string,
    description: string,
    objective: string,
    image?: string,
    isPublished?: boolean,
    duration?: number,
    status?: number,
    templateJson: string,
    cluesJson: string,
    solutionJson: string,
    creator: string,
    updater?: string,
    deletedAt?: string
}

export interface ActivityTemplateFilter {
    filter: {
        limit?: number,
        offset?: number
        title?: string,
        status?: number,
        isPublished?: boolean,
        orderBy?: {
            [key: string]: string
        }
    }
}

export interface TemplateLocator {
    activeSectionIndex: number;
    activeWidgetIndex: number;
}

@Module
export default class TemplateModule extends VuexModule {
    // Activity Template specific properties
    activityTemplate = {}
    activityTemplates = [] as Array<ActivityTemplate>
    latestTemplates = [] as Array<ActivityTemplate>
    activityTemplatesCount = 0;
    allTemplatesCount = 0;
    readyTemplatesCount = 0;
    draftTemplatesCount = 0;

    // Template specific properties
    template = {
      templateUuid: '' as string,
      title: '' as string,
      description: '' as string,
      sections: [{
        sectionUuid: '' as string,
        title: '' as string,
        widgets: [] as Array<Widget>
      }] as Array<Section>
    } as Template

    solvingMode = true as boolean
    activeSectionIndex = 0 as number
    activeWidgetIndex = 0 as number
    mobileViewMode = false;
    activityTemplatesRequestPending = false;
    discoveredWidgets = [] as Array<String>
    expandedHotspotWidgets = [] as any
    selectedWidgetScene = [] as any
    selectedHotspotInScene = [] as any

    get getActivityTemplate () {
      return this.activityTemplate
    }

    get getExpandedHotspotWidgets () {
      return this.expandedHotspotWidgets
    }

    /**
     * Get the api request status for activity templates
     * @returns boolean
     */
    get getActivityTemplatesRequestPending (): boolean {
      return this.activityTemplatesRequestPending
    }

    /**
     * Get the list of activity templates filtered
     * @returns array
     */
    get getActivityTemplates (): Array<ActivityTemplate> {
      return this.activityTemplates
    }

    get getActivityTemplatesCount (): number {
      return this.activityTemplatesCount
    }

    get getLatestTemplates (): Array<ActivityTemplate> {
      return this.latestTemplates
    }

    /**
     * Get template
     * @returns Template
     */
    get getTemplate (): Template {
      return this.template
    }

    /**
     * Get template solving mode state
     * @returns boolean
     */
    get getSolvingMode (): boolean {
      return this.solvingMode
    }

    /**
     * Get template mobile view mode state
     * @returns boolean
     */
    get getMobileViewMode (): boolean {
      return this.mobileViewMode
    }

    /**
     * Get sections
     * @returns Array<Section>
     */
    get getSections (): Array<Section> {
      return this.template.sections
    }

    /**
     * Get active section
     * @returns Section
     */
    get getActiveSection (): Section {
      return this.template.sections[this.activeSectionIndex]
    }

    /**
     * Get active widget
     * @returns Widget
     */
    get getActiveWidget (): Widget {
      return this.template.sections[this.activeSectionIndex]?.widgets[this.activeWidgetIndex]
    }

    /**
     * Get active section widgets positions
     * @returns Array<WidgetPosition>
     */
    get getActiveSectionWidgetsLayout (): Array<WidgetPosition> {
      const widgets = this.template.sections[this.activeSectionIndex].widgets
      const widgetsLayout = [] as Array<WidgetPosition>
      widgets.forEach((widget) => {
        const widgetPosition = widget.position as WidgetPosition
        widgetPosition.i = widget.widgetUuid
        widgetsLayout.push(widgetPosition)
      })
      return widgetsLayout
    }

    /**
     * Get active section widgets
     * @returns Array<Widget>
     */
    get getActiveSectionWidgets (): Array<Widget> {
      return this.template.sections[this.activeSectionIndex].widgets
    }

    /**
     * Get active section widgets mobile position
     * @returns Array<WidgetMobilePosition>
     */
    get getActiveSectionWidgetsMobilePosition (): Array<WidgetMobilePosition> {
      if (this.activeSectionIndex === -1) { // this <if> will prevent breaking in debriefing tab
        return []
      }
      if ( // cope with old templates that did not have mobile position
        this.template.sections[this.activeSectionIndex].widgetsMobilePosition === undefined ||
            this.template.sections[this.activeSectionIndex].widgetsMobilePosition.length === 0
      ) {
        this.template.sections[this.activeSectionIndex].widgetsMobilePosition = []
        this.template.sections[this.activeSectionIndex].widgets.forEach((widget) => {
          this.template.sections[this.activeSectionIndex].widgetsMobilePosition.push({ widgetUuid: widget.widgetUuid })
        })
      }

      return this.template.sections[this.activeSectionIndex].widgetsMobilePosition
    }

    get getHotspotsFor360ImageScene (): (widgetUuid: string, sceneUuid: string) => object {
      const _this = this
      return function (widgetUuid: string, sceneUuid: string): any {
        const widget = _this.template.sections[_this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === widgetUuid)
        if (widget) {
          const scene = widget.specific.scenes.find((scene) => scene.sceneUuid === sceneUuid)
          if (scene) {
            return scene.hotspots
          }
        }
      }
    }

    get getScenesFor360ImageWidget (): (widgetUuid: string) => object {
      const _this = this
      return function (widgetUuid: string): any {
        const widget = _this.template.sections[_this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === widgetUuid)
        if (widget) {
          return widget.specific.scenes
        }
      }
    }

    get getSelectedSceneForWidget (): (widgetUuid: string) => string | null {
      const _this = this
      return function (widgetUuid: string): any {
        if (typeof _this.selectedWidgetScene[widgetUuid] !== 'undefined' && _this.selectedWidgetScene[widgetUuid] !== null) {
          return _this.selectedWidgetScene[widgetUuid]
        } else {
          const widget = _this.template.sections[_this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === widgetUuid)
          if (widget) {
            if (widget.specific.scenes.length >= 0) {
              return widget.specific.scenes[0]
            } else {
              return null
            }
          }
        }
      }
    }

    /**
     * Get active section index
     * @returns number
     */
    get getActiveSectionIndex (): number {
      return this.activeSectionIndex
    }

    /**
     * Get active widget index
     * @returns number
     */
    get getActiveWidgetIndex (): number {
      return this.activeWidgetIndex
    }

    /**
     * Get a section locked status by UUID based on previous section having unsolved widgets
     * @returns boolean
     */
    get getSectionIsLockedByResource (): (sectionUuid: string) => boolean {
      const _this = this

      return function (sectionUuid: string): boolean {
        for (let sectionKey = 0; sectionKey < _this.template.sections.length; sectionKey++) {
          const section = _this.template.sections[sectionKey]
          if (section.sectionUuid !== sectionUuid || sectionKey === 0) continue
          for (let previousSectionKey = 0; previousSectionKey < sectionKey; previousSectionKey++) {
            const previousSection = _this.template.sections[previousSectionKey]
            for (let w = 0; w < previousSection.widgets.length; w++) {
              if (previousSection.widgets[w].type === 'question-team-readiness' && !previousSection.widgets[w].specific.solved) {
                return true
              }
              if (previousSection.widgets[w].type === 'image-view-360' && previousSection.widgets[w].specific.scenes.length) {
                for (let sceneKey = 0; sceneKey < previousSection.widgets[w].specific.scenes.length; sceneKey++) {
                  for (let hotspotKey = 0; hotspotKey < previousSection.widgets[w].specific.scenes[sceneKey].hotspots.length; hotspotKey++) {
                    if (previousSection.widgets[w].specific.scenes[sceneKey].hotspots[hotspotKey].modalWidget.type) {
                      if (previousSection.widgets[w].specific.scenes[sceneKey].hotspots[hotspotKey].modalWidget.type === 'question-team-readiness' && !previousSection.widgets[w].specific.scenes[sceneKey].hotspots[hotspotKey].modalWidget.specific.solved) {
                        return true
                      }
                    }
                  }
                }

                // if(previousSection.widgets[w].specific.modalWidget.)
              }
            }
          }
        }
        return false
      }
    }

    /**
     * Get a widget by UUID
     * @returns Widget
     */
    get getWidgetByUuid (): (widgetUuid: string, fromOriginalTemplate: boolean) => Widget | null {
      const _this = this

      return function (widgetUuid: string, fromOriginalTemplate: boolean): Widget | null {
        if (fromOriginalTemplate) { // on curiosity this <if> will look into insights in case we want the original widget - this case is used in debriefing
          const insights = computed(() => store.getters.getActivitySessionsInsights)

          for (let i = 0; i < insights.value.length; i++) {
            const insight = insights.value[i].originalWidget
            if (insight.widgetUuid === widgetUuid) return insight
          }
          return null
        } else {
          for (let s = 0; s < _this.template.sections.length; s++) {
            const section = _this.template.sections[s]
            for (let w = 0; w < section.widgets.length; w++) {
              if (section.widgets[w].widgetUuid === widgetUuid) {
                return _this.template.sections[s].widgets[w]
              }
            }
          }
          return null
        }
      }
    }

    /**
     * Get a widget index by UUID
     * @returns number
     */
    get getWidgetIndexByUuid (): (widgetUuid: string) => number | null {
      const _this = this
      return function (widgetUuid: string): number | null {
        for (let s = 0; s < _this.template.sections.length; s++) {
          const section = _this.template.sections[s]
          for (let w = 0; w < section.widgets.length; w++) {
            if (section.widgets[w].widgetUuid === widgetUuid) {
              return w
            }
          }
        }
        return null
      }
    }

    get getAllTemplatesCount (): number {
      return this.allTemplatesCount
    }

    get getReadyTemplatesCount (): number {
      return this.readyTemplatesCount
    }

    get getDraftTemplatesCount (): number {
      return this.draftTemplatesCount
    }

    get getTemplateSectionByIndex (): (index: number) => Section {
      const _this = this
      return function (index: number): Section {
        return _this.template.sections[index] as Section
      }
    }

    get getUploadedFilesForFileUploadWidget (): (widgetUuid: string) => object {
      const _this = this
      return function (widgetUuid: string): any {
        if (_this.activeSectionIndex === -1) return {}
        const widget = _this.template.sections[_this.activeSectionIndex].widgets.find((widget) => widget.widgetUuid === widgetUuid)
        if (widget) {
          return widget.specific.files
        }
      }
    }

    /**
     * Get template discovered widgets
     * @returns Array
     */
    get getDiscoveredWidgets (): Array<String> {
      return this.discoveredWidgets
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_TEMPLATE] (templateUuid) {
      const response = await ApiService.get('api/v1/activity-template/' + templateUuid)
      const template = response.data.payload.templateJson
      this.context.commit(Mutations.SET_ACTIVITY_TEMPLATE, response.data.payload)
      console.log('Template JSON String:')
      console.log(JSON.stringify(template))
      console.table('Template JSON: ', template)
      return { template }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_TEMPLATE_BY_TEAM] (payload) {
      console.log('API_GET_MUTATE_TEMPLATE_BY_TEAM: ', payload.templateUuid, payload.teamUuid)
      const response = await ApiService.get('api/v1/activity-template/' + payload.templateUuid + '/team/' + payload.teamUuid)
      const template = response.data.payload.templateJson
      this.context.commit(Mutations.SET_ACTIVITY_TEMPLATE, response.data.payload)
      console.log('Template JSON String:')
      console.log(JSON.stringify(template))
      console.table('Template JSON: ', template)
      return { template }
    }

    @Mutation
    [Mutations.SET_ACTIVITY_TEMPLATE] (activityTemplate) {
      this.activityTemplate = activityTemplate
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES] (payload) {
      this.context.commit(Mutations.API_SET_ACTIVITY_TEMPLATES_REQUEST_PENDING, true)
      const response = await ApiService.get('api/v1/activity-template', '', payload)
      if (response.status === 200) {
        this.context.commit(Mutations.API_SET_ACTIVITY_TEMPLATES_REQUEST_PENDING, false)
      }
      const activityTemplates = response.data.payload
      return { activityTemplates }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_LATEST_ACTIVITY_TEMPLATES] (payload) {
      const response = await ApiService.get('api/v1/activity-template', '', payload)
      const latestTemplates = response.data.payload
      return { latestTemplates }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ACTIVITY_TEMPLATES_COUNT] (payload) {
      const response = await ApiService.get('api/v1/activity-template', 'count', payload)
      const activityTemplatesCount = response.data.payload.count
      return { activityTemplatesCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_READY_TEMPLATES_COUNT] (payload) {
      const response = await ApiService.get('api/v1/activity-template', 'count', payload)
      const readyTemplatesCount = response.data.payload.count
      return { readyTemplatesCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_DRAFT_TEMPLATES_COUNT] (payload) {
      const response = await ApiService.get('api/v1/activity-template', 'count', payload)
      const draftTemplatesCount = response.data.payload.count
      return { draftTemplatesCount }
    }

    @MutationAction
    async [MutationsActions.API_GET_MUTATE_ALL_TEMPLATES_COUNT] (payload) {
      const response = await ApiService.get('api/v1/activity-template', 'count', payload)
      const allTemplatesCount = response.data.payload.count
      return { allTemplatesCount }
    }

    @Action({ rawError: true })
    async [Actions.API_CREATE_ACTIVITY_TEMPLATE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template', payload)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UPLOAD_ACTIVITY_TEMPLATE_IMAGE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + payload.templateUuid + '/image', payload.data, payload.config)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UPDATE_ACTIVITY_TEMPLATE] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + payload.templateUuid, payload.data, payload.config)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_DELETE_ACTIVITY_TEMPLATE] (templateUuid) {
      return new Promise((resolve, reject) => {
        ApiService.delete('api/v1/activity-template/' + templateUuid)
          .then(({ data }) => {
            resolve(data)
            this.context.commit(Mutations.REMOVE_ACTIVITY_TEMPLATE, templateUuid)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Action({ rawError: true })
    async [Actions.API_UNLOCK_SECTION] (payload) {
      return new Promise((resolve, reject) => {
        ApiService.post('api/v1/activity-template/' + payload.templateUuid + '/team/' + payload.teamUuid + '/section/unlock', payload.data)
          .then(({ data }) => {
            resolve(data)
          })
          .catch(({ response }) => {
            reject(response)
          })
      })
    }

    @Mutation
    [Mutations.SET_ACTIVITY_TEMPLATE_SOLVING_MODE] (solvingMode) {
      this.solvingMode = solvingMode
    }

    @Mutation
    [Mutations.SET_ACTIVITY_TEMPLATE_MOBILE_VIEW_MODE] (mobileViewMode) {
      this.mobileViewMode = mobileViewMode
    }

    @Mutation
    [Mutations.SET_TEMPLATE_ACTIVE_SECTION_INDEX] (sectionIndex) {
      // save clicked section to storage so unlock animation does not activate on reload
      if (!SessionStorage.getItem('clickedSection-template-' + this.template.templateUuid)) {
        SessionStorage.saveItem('clickedSection-template-' + this.template.templateUuid, JSON.stringify([]))
      }
      let currentClickedSections = SessionStorage.getItem('clickedSection-template-' + this.template.templateUuid)
      if (currentClickedSections !== null) {
        currentClickedSections = JSON.parse(currentClickedSections)
        // @ts-ignore
        if (!currentClickedSections.some(item => item.sectionIndex === sectionIndex)) {
          // @ts-ignore
          currentClickedSections.push({ sectionIndex: sectionIndex })
        }
        SessionStorage.saveItem('clickedSection-template-' + this.template.templateUuid, JSON.stringify(currentClickedSections))
      }

      this.activeSectionIndex = sectionIndex
    }

    @Mutation
    [Mutations.SET_TEMPLATE_ACTIVE_WIDGET_INDEX] (widgetIndex) {
      this.activeWidgetIndex = widgetIndex
    }

    @Mutation
    [Mutations.SET_TEMPLATE_ADD_SECTION] (newSection) {
      this.template.sections.push(newSection)
      this.activeSectionIndex = this.template.sections.length - 1
    }

    @Mutation
    [Mutations.SET_TEMPLATE_REMOVE_SECTION] (sectionIndex) {
      this.template.sections.splice(sectionIndex, 1)
      if (this.activeSectionIndex >= sectionIndex && sectionIndex !== 0) {
        this.activeSectionIndex--
      }
    }

    @Mutation
    [Mutations.SET_TEMPLATE_ADD_WIDGET] (payload) {
      this.template.sections[this.activeSectionIndex].widgets.push(payload.widget)
      this.template.sections[this.activeSectionIndex].widgetsMobilePosition.push({ widgetUuid: payload.widget.widgetUuid })
    }

    @Mutation
    [Mutations.SET_TEMPLATE_EDIT_WIDGET] (payload) {
      Object.assign(this.template.sections[payload.activeSectionIndex].widgets[payload.activeWidgetIndex], payload.widget)
    }

    @Mutation
    [Mutations.SET_TEMPLATE_REMOVE_WIDGET_BY_UUID] (widgetUuid) {
      this.template.sections[this.activeSectionIndex].widgets = this.template.sections[this.activeSectionIndex].widgets.filter(widget => widget.widgetUuid !== widgetUuid)
      this.template.sections[this.activeSectionIndex].widgetsMobilePosition = this.template.sections[this.activeSectionIndex].widgetsMobilePosition.filter(widgetMobPos => widgetMobPos.widgetUuid !== widgetUuid)
    }

    @Mutation
    [Mutations.API_SET_ACTIVITY_TEMPLATES_REQUEST_PENDING] (value) {
      this.activityTemplatesRequestPending = value
    }

    @Mutation
    [Mutations.SET_SELECTED_SCENE_FOR_WIDGET] (payload) {
      this.selectedWidgetScene[payload.widgetUuid] = payload.scene
    }

    @Mutation
    [Mutations.SET_SELECTED_HOTSPOT_IN_SCENE] (payload) {
      this.selectedHotspotInScene[payload.sceneUuid] = payload.hotspot
    }

    @Mutation
    [Mutations.UPDATE_WIDGET_EDIT_HOTSPOT] (payload) {
      const sections = this.template.sections
      let widgetIndex = -1
      for (let i = 0; i < sections.length; i++) {
        const widgets = sections[i].widgets
        const widget = widgets.find((widget) => widget.widgetUuid === payload.widgetUuid)

        if (widget) {
          widgetIndex = widgets.indexOf(widget)
          // Find the scene that contains the hotspot
          const scenes = widget.specific.scenes
          for (let j = 0; j < scenes.length; j++) {
            const scene = scenes[j]
            const hotspotIndex = scene.hotspots.findIndex((hotspot) => hotspot.hotspotUuid === payload.hotspot.hotspotUuid)

            if (hotspotIndex !== -1) {
              this.template.sections[i].widgets[widgetIndex].specific.scenes[j].hotspots[hotspotIndex] = payload.hotspot
              break // Exit the loop if hotspot is found
            }
          }
        }
      }
    }

    @Mutation
    [Mutations.REMOVE_ACTIVITY_TEMPLATE] (activityUuid) {
      let removedActivity = {} as any
      for (let i = 0; i < this.activityTemplates.length; i++) {
        if (this.activityTemplates[i].activityTemplateUuid === activityUuid) {
          removedActivity = this.activityTemplates[i]
          this.activityTemplates.splice(i, 1)
        }
      }

      this.allTemplatesCount = this.allTemplatesCount - 1
      this.activityTemplatesCount = this.activityTemplatesCount - 1

      if (removedActivity.status === 2) {
        this.readyTemplatesCount = this.readyTemplatesCount - 1
      }

      if (removedActivity.status === 1) {
        this.draftTemplatesCount = this.draftTemplatesCount - 1
      }
    }

    @Mutation
    [Mutations.UPDATE_SECTION_MODULE] (updatedSection: Section) {
      const foundSection = this.template.sections.find(section => section.sectionUuid === updatedSection.sectionUuid)
      if (typeof foundSection !== 'undefined') {
        // do not update widgets because they might not be updated
        // widgets are updated via other messages
        updatedSection.widgets = foundSection.widgets
        Object.assign(foundSection, updatedSection)
      }
    }

    @Mutation
    [Mutations.UPDATE_WIDGET_POSITION] (payload: any) {
      const sections = this.template.sections
      let widgetIndex = -1
      for (let i = 0; i < sections.length; i++) {
        const widgets = sections[i].widgets
        const widget = widgets.find((widget) => widget.widgetUuid === payload.widgetUuid)

        if (widget) {
          widgetIndex = widgets.indexOf(widget)
          this.template.sections[i].widgets[widgetIndex].position = payload.widgetPosition
        }
      }
    }

    @Mutation
    [Mutations.SET_TEMPLATE_WIDGET_DISCOVERED] (widgetUuid) {
      this.discoveredWidgets = [widgetUuid]
    }

    @Mutation
    [Mutations.ADD_HOTSPOT_EXPANDED_WIDGETS] (widgetUuid) {
      this.expandedHotspotWidgets = [widgetUuid]
    }

    @Mutation
    [Mutations.REMOVE_HOTSPOT_EXPANDED_WIDGETS] (widgetUuid) {
      this.expandedHotspotWidgets = this.expandedHotspotWidgets.filter((expandedWidgetUuid) => expandedWidgetUuid !== widgetUuid)
    }
}
