
import { defineComponent, onMounted, nextTick, computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'
// import { initializeComponents } from '@/core/plugins/keenthemes'
import { Actions } from '@/store/enums/StoreEnums'
import { useRoute, useRouter } from 'vue-router'
import SessionStorage from '@/core/services/SessionStorage'
import { StatisticDataInterface } from '@/store/modules/ActiveUserModule'

export default defineComponent({
  name: 'app',
  setup () {
    const store = useStore()
    const router = useRouter()
    const socket = computed(() => store.getters.getSocket)

    const handleSave = (seconds) => {
      // first save it session storage
      SessionStorage.saveItem('activeInTab', seconds)
      // then send it to backend to be saved in redis
      if (socket.value) {
        const newStatisticData = { type: 'activeInTab', meta: { seconds: Math.round(seconds) } } as unknown as StatisticDataInterface

        socket.value.emit('ADD_STATISTIC_DATA', {
          eventType: 'ADD_STATISTIC_DATA',
          payload: newStatisticData
        })
      }
    }
    // const handleVisibility = () => {
    //   if (document.visibilityState === 'hidden') {
    //     console.log('visibility hidden')
    //     const loggedInAt = SessionStorage.getItem('loggedInAt')
    //     if (!loggedInAt) {
    //       return
    //     }
    //
    //     const t1 = new Date(loggedInAt)
    //     const t2 = new Date() // now
    //
    //     const diffInSecondsFromStart = (t2.getTime() - t1.getTime()) / 1000
    //
    //     const lastTabChangeAt = SessionStorage.getItem('tabLastChangedBackAt')
    //
    //     if (lastTabChangeAt) {
    //       const t3 = new Date(lastTabChangeAt)
    //       const diffInSecondsFromChange = (t2.getTime() - t3.getTime()) / 1000 as any
    //       const activeInTab = SessionStorage.getItem('activeInTab') as any
    //       SessionStorage.saveItem('activeInTab', parseInt(diffInSecondsFromChange) + parseInt(activeInTab))
    //       const newStatisticData = { type: 'activeInTab', meta: { seconds: diffInSecondsFromStart } } as unknown as StatisticDataInterface
    //
    //       console.log(newStatisticData)
    //       return
    //     }
    //
    //     SessionStorage.saveItem('activeInTab', diffInSecondsFromStart)
    //
    //     // // SessionStorage.saveItem('lastTabChangeAt', new Date())
    //     //
    //     // // pause animation, video, etc
    //     // console.log('paused at ' + new Date())
    //     // const t1 = new Date('2021-08-25 07:41:07')
    //     // const t2 = new Date('2021-08-25 07:50:07')
    //     // const dif = (t2.getTime() - t1.getTime()) / 1000
    //     // console.log(dif)
    //   } else {
    //     console.log('visibility not hidden')
    //
    //     SessionStorage.saveItem('tabLastChangedBackAt', new Date())
    //
    //     console.log('continue at ' + new Date())
    //     // continue operation
    //   }
    // }

    const handleVisibilityOnFocus = () => {
      const loggedInAt = SessionStorage.getItem('loggedInAt')
      if (!loggedInAt) {
        return
      }

      SessionStorage.saveItem('tabLastChangedBackAt', new Date())
    }
    const handleVisibilityOnBlur = () => {
      const loggedInAt = SessionStorage.getItem('loggedInAt')
      if (!loggedInAt) {
        return
      }

      const t1 = new Date(loggedInAt)
      const t2 = new Date() // now

      const diffInSecondsFromStart = (t2.getTime() - t1.getTime()) / 1000

      const lastTabChangeAt = SessionStorage.getItem('tabLastChangedBackAt')

      if (lastTabChangeAt) {
        const t3 = new Date(lastTabChangeAt)
        const diffInSecondsFromChange = (t2.getTime() - t3.getTime()) / 1000 as any
        const activeInTab = SessionStorage.getItem('activeInTab') as any
        handleSave(parseInt(diffInSecondsFromChange) + parseInt(activeInTab))
        // SessionStorage.saveItem('activeInTab', parseInt(diffInSecondsFromChange) + parseInt(activeInTab))

        return
      }
      // const newStatisticData = { type: 'activeInTab', meta: { seconds: diffInSecondsFromStart } } as unknown as StatisticDataInterface
      //
      // console.log(newStatisticData)
      handleSave(diffInSecondsFromStart)

      // SessionStorage.saveItem('activeInTab', diffInSecondsFromStart)
    }

    const handleBeforeUnload = () => {
      SessionStorage.saveItem('beforeUnloaded at', new Date())
    }

    const handleUnload = () => {
      SessionStorage.saveItem('unloaded at', new Date())
    }
    window.addEventListener('beforeunload', handleBeforeUnload, false)
    window.addEventListener('unload', handleUnload, false)
    const route = useRoute()

    const path = computed(() => route.name) as any
    // no need for token here
    // console.log('path.value', path.value)
    if (!['engage', 'engagePublic', 'engageTryItNow', 'engageTryItNowLanding', '401', '404', '500'].includes(path.value)) {
      store.dispatch(Actions.SET_TOKEN).then(() => {
        store.dispatch(Actions.FETCH_USER).catch((error) => {
          console.error('fetching user', error)
          store.dispatch(Actions.CLEAR_AUTHENTICATION).then(() => {
            router.replace({ name: 'sign-in' })
          })
        })
      }).catch((error) => {
        console.error('setting token', error)
        store.dispatch(Actions.CLEAR_AUTHENTICATION)
      })
    }
    onMounted(async () => {
      // await router.isReady()
      // if (!store.getters.isAccountAuthenticated &&
      //     router.currentRoute.value.name !== 'engage' &&
      //     router.currentRoute.value.name !== '401' &&
      //     router.currentRoute.value.name !== '404' &&
      //     router.currentRoute.value.name !== '500') {
      //   router.push({ name: '401' })
      // }
    })
    onMounted(() => {
      /**
       * this is to override the layout config using saved data from localStorage
       * remove this to use config only from static config (@/core/config/DefaultLayoutConfig.ts)
       */
      // store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG)
      const details = reactive({
        account: computed(() => store.getters.currentActiveUser),
        socket: computed(() => store.getters.getSocket)
      })
      // const errors = reactive({ error: computed(() => store.getters.getAdminErrors) })
      // watch(() => errors.error, (newError, oldErrors) => {
      //   if (newError.code) {
      //     ElNotification.error({
      //       message: newError.message,
      //       dangerouslyUseHTMLString: true,
      //       customClass: 'houston-notification',
      //       position: 'top-left',
      //       duration: 10000
      //     })
      //   }
      // })

      let socketConnected = false

      watch(() => details.account, (account, oldAccount) => {
        console.log('account: ', account)
        if (account.activeUserUuid && !socketConnected) {
          console.log('creating new socket connection for account', account.socket)
          const payloadSocket = {} as any
          payloadSocket.userSessionId = account.activeUserUuid
          payloadSocket.teamUuid = account.teamUuid
          store.dispatch(Actions.CREATE_SOCKET_CONNECTION, payloadSocket).then((response) => {
            socketConnected = true
          })
        }
      })

      // document.addEventListener('visibilitychange', handleVisibility, false)

      window.addEventListener('focus', handleVisibilityOnFocus)
      window.addEventListener('blur', handleVisibilityOnBlur)

      nextTick(() => {
        // initializeComponents()
      })
    })
  }
})
