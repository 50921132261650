import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Actions, Mutations } from '@/store/enums/StoreEnums'
import store from '@/store'

export const DISCONNECT = 'disconnect'
export const RESOURCE_LOCK_ENTER = 'team:%teamUuid%:resource:lock:enter'
export const RESOURCE_LOCK_LEAVE = 'team:%teamUuid%:resource:lock:leave'
export const RESOURCE_LOCK_LIST = 'team:%teamUuid%:resource:lock:list'

@Module
export default class ResourceLockerModule extends VuexModule {
    lockList = []
    get isResourceLocked (): (resourceId: string) => Boolean {
      const _this = this
      return function (resourceId: string): Boolean {
        const socket = store.getters.getSocket
        return typeof _this.lockList.find((item: any) => item.resourceId === resourceId && item.teamUuid === socket.io.opts.query.teamUuid && item.clientId !== socket.io.opts.query.userSessionId) !== 'undefined'
      }
    }

    get getLockedResource (): (resourceId: string) => any {
      const _this = this
      return function (resourceId: string): any {
        const socket = store.getters.getSocket
        return _this.lockList.find((item: any) => item.resourceId === resourceId && item.teamUuid === socket.io.opts.query.teamUuid)
      }
    }

    get getModuleUserResources (): (moduleUuid: string) => any {
      const _this = this
      return function (moduleUuid: string): any {
        const socket = store.getters.getSocket
        return _this.lockList.filter((item: any) => item.teamUuid === socket.io.opts.query.teamUuid && item.resourceId.indexOf(('module-' + moduleUuid)) !== -1)
      }
    }

    get getSectionUserResources (): (sectionUuid: string) => any {
      const _this = this
      return function (sectionUuid: string): any {
        const socket = store.getters.getSocket
        return _this.lockList.filter((item: any) => item.teamUuid === socket.io.opts.query.teamUuid && item.resourceId.indexOf(('section-' + sectionUuid)) !== -1)
      }
    }

    @Action({ rawError: true })
    async [Actions.LOCK_RESOURCE] (resourceId) {
      const socket = store.getters.getSocket
      return new Promise(resolve => {
        socket.emit(RESOURCE_LOCK_ENTER.replace('%teamUuid%', socket.io.opts.query.teamUuid), { resourceId: resourceId }, resolve)
      })
    }

    @Action({ rawError: true })
    async [Actions.UNLOCK_RESOURCE] (resourceId) {
      const socket = store.getters.getSocket
      return new Promise(resolve => {
        socket.emit(RESOURCE_LOCK_LEAVE.replace('%teamUuid%', socket.io.opts.query.teamUuid), { resourceId: resourceId }, resolve)
      })
    }

    @Mutation
    [Mutations.UPDATE_LOCK_LIST] (lockList: []) {
      this.lockList = lockList
    }
}
